//Custom Styles here.

.hero img {
  max-width: 350px !important;
}

.home_footer {
  text-align: center;
  padding-bottom: 1rem;
}

.home_footer div {
  font-size: 17px;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

:root {
  scroll-behavior: smooth;
  --back-to-top-z-index: 5;
  --back-to-top-color: #3056d5;
  --back-to-top-color-hover: #3056d5;
  --content-width: inherit !important;

  --vp-c-accent: #3D50F5;
  --vp-c-accent-bg: #3D50F5;
  --vp-c-accent-hover: #4e5ff8;
  --badge-c-tip-bg: #45d48c;
}

:root[data-theme='dark'] {
  --vp-c-accent: #B3C7FF;
  --vp-c-accent-bg: #B3C7FF;
  --vp-c-accent-hover: #778bc2;
  --badge-c-tip-bg: #30d56f;
}

.sidebar::-webkit-scrollbar {
  width: 0%;
  height: 0%;
}

.navbar{
  background-color: var(--c-nav);
  border: none;
}

.sidebar{
  background-color: var(--c-nav);
  font-size: 14px;
  border: none;
  margin-top: 1px;
}

.header-anchor{
  color: var(--c-header);
}

.header-anchor p{
  color: var(--c-text);
}
.scrollable-code div[class^="language-"]{
  max-height: 450px;
  overflow-y: auto;
}

.scrollable-code div pre{
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.scrollable-code div .line-numbers{
  border-right: 1px solid var(--code-hl-bg-color);
  border-radius: 6px 0 0 6px;
  padding-bottom: 1.3rem;
}

.scrollable-code div[class^="language-"]::after {
  display: none;
}

div.theme-default-content:not(.custom), footer.page-meta:not(.custom), nav.page-nav:not(.custom) {
  max-width: 1800px;
}
